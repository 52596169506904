import React, { useEffect } from "react";
import {
  Box,
  CssBaseline,
  Grid,
  ThemeProvider,
  Typography,
  Stack,
  TextField,
} from "@mui/material";
import { Trans } from "@lingui/macro";
import { LocalizedLink as Link, useLocalization } from "gatsby-theme-i18n";
import styled from "@emotion/styled";
import parse from "html-react-parser";
import Reaptcha from "reaptcha";
import { theme as ourTheme } from "../theme";
import whitepapers from "../cms/whitepapers/whitepapers";
import logoPositive from "../images/logo.png";
import Mail from "../services/MailService";
import LoadingButton from "../components/LoadingButton";

const inputs = () => [
  { value: "name", label: <Trans>Full name</Trans> },
  { value: "company", label: <Trans>Company</Trans> },
  { value: "email", label: <Trans>Email</Trans> },
];

function WhitePaper({ pageContext }) {
  const { locale } = useLocalization();
  if (!whitepapers[locale]?.[pageContext.slug]) return null;
  const {
    attributes: {
      title,
      description,
      content,
      image,
      link,
      logo,
      file,
      subject,
      client,
    },
  } = whitepapers[locale][pageContext.slug];
  const [data, setData] = React.useState({
    type: "contactDisabled",
    subject,
    client,
  });
  const [isLoadedVideo, setIsLoadedVideo] = React.useState(false);
  const [isSent, setIsSent] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const sendContact = () => {
    if (data["g-recaptcha-response"] && Mail.validate(inputs(), data)) {
      setLoading(true);
      Mail.sendContactRequest(data).then(({ status }) => {
        if (status === 200) {
          file &&
            setTimeout(() => {
              window?.open(file.data.attributes.url, "_blank");
            }, 3000);
          setLoading(false);
          setIsSent(true);
          setData({});
        }
      });
    }
  };

  useEffect(() => {
    document.getElementById("vid").play();
    setIsLoadedVideo(true);
  }, []);

  return (
    <ThemeProvider theme={ourTheme}>
      <CssBaseline />
      <Box>
        <StyledBox
          sx={{
            backgroundImage: {
              xs: `linear-gradient(90deg, rgba(27,54,93,1) 0%, rgba(126,5,5,0) 100%), url(${image.data.attributes.url})`,
              sm: !isLoadedVideo
                ? `linear-gradient(90deg, rgba(27,54,93,1) 0%, rgba(126,5,5,0) 100%), url(${image.data.attributes.url})`
                : "linear-gradient(90deg, rgba(27,54,93,1) 0%, rgba(126,5,5,0) 100%)",
            },
          }}
        >
          <video id="vid" autoPlay muted loop>
            <source src={link} type="video/mp4" />
          </video>
          <Box
            sx={{
            px: { xs: 2, sm: 8 },
            pt: 4,
            pb: { xs: 4, sm: 8 },
            position: "absolute",
          }}
        >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              textAlign={{ xs: "start" }}
              spacing={2}
          >
              <Grid item xs={12} sm={6}>
                <Link to="/">
                  <img alt="allfunds_logo" src={logoPositive} />
                </Link>
              </Grid>
              <Grid item xs={12} sm={6} textAlign={{ sm: "end" }}>
                <img alt="logo" src={logo.data.attributes.url} />
              </Grid>
            </Grid>
            <Box className="content">
              <Grid container spacing={{ xs: 2, sm: 4 }}>
                <Grid item xs={12}>
                  <Typography variant="h1">{title}</Typography>
                  <Typography variant="h3" fontWeight={400}>
                    {description}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography fontSize={16}>{parse(content)}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {isSent ? (
                    <Stack spacing={2} textAlign="center">
                      <Typography variant="h1">
                        <Trans>Thank you!</Trans>
                      </Typography>
                      <Typography>
                        <Trans>
                          If you would like further information, please don’t
                          hesitate to contact us
                        </Trans>{" "}
                        <a href="mailto:allfundsconnect@allfunds.com">
                          allfundsconnect@allfunds.com
                        </a>
                      </Typography>
                    </Stack>
                ) : (
                  <Stack spacing={2}>
                    {inputs().map(({ value, label }) => (
                      <TextField
                        value={data[value] || ""}
                        label={label}
                        focused
                        color="inherit"
                        fullWidth
                        onChange={(e) =>
                          setData({ ...data, [value]: e.target.value })}
                        required
                        sx={{ input: { color: "white" } }}
                      />
                    ))}
                    <Reaptcha
                      hl={locale}
                      sitekey={process.env.GATSBY_RECAPTCHA_KEY}
                      onVerify={(token) =>
                        setData({ ...data, "g-recaptcha-response": token })}
                    />
                    <LoadingButton
                      variant="contained"
                      color="secondary"
                      onClick={() => sendContact()}
                      loading={loading}
                    >
                      <Typography>
                        <Trans>Download report</Trans>
                      </Typography>
                    </LoadingButton>
                    <Typography variant="small">
                      <Trans>
                        By submitting this form you agree we might contact you
                        only for the purpose of following up on your interest in
                        this topic.
                      </Trans>
                    </Typography>
                  </Stack>
                )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </StyledBox>
      </Box>
    </ThemeProvider>
  );
}

const StyledBox = styled(Box)(
  ({ theme }) => `
  height: 100vh;
  width: 100%;
  position: absolute;
  background-size: cover !important;
  overflow-x: hidden;
  > div {
    min-height: 100vh;
    width: 100%;
    ${theme.breakpoints.up("sm")} { 
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    ${theme.breakpoints.down("sm")} { 
      .content{
        margin-top: ${theme.spacing(2)};
      }
    }
  }
  #vid{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
  }
  > div, * h1, p, span, a {
    color: white;
  }
`,
);

export default WhitePaper;
